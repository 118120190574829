<template>
  <v-dialog v-model="localDialog" max-width="600px">
    <v-card>
      <v-card-title>
        <span class="headline">{{ $t("license.tos.title") }}</span>
      </v-card-title>
      <v-card-text> {{ $t("license.tos.description") }}</v-card-text>
      <v-card-text>
        <a target="_blank" href="/nodeledge-tos.pdf"
          >Read License Agreement here</a
        >
      </v-card-text>
      <v-card-text>
        <v-checkbox v-model="accepted" :label="$t('license.tos.checkbox')" />
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>

        <v-btn color="primary lighten-1" text @click="localDialog = false">
          {{ $t("common.close") }}
        </v-btn>
        <v-btn
          color="primary"
          type="submit"
          x-large
          :disabled="!accepted"
          @click="accept"
        >
          {{ $t("license.tos.accept") }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import LicenseRepository from "@/api/repositories/licenseRepository";

export default {
  name: "TosDialog",

  props: {
    email: { type: String, default: "" },
  },

  data() {
    return { localDialog: false, accepted: false };
  },

  methods: {
    async accept() {
      let result = await LicenseRepository.acceptTos(this.email)
        .then((d) => d.data)
        .catch(() => false);
      if (!result)
        console.error("Could not accept TOS letting the login go through once");

      this.localDialog = false;
      this.$emit("accepted");
    },

    async openDialog() {
      if (
        !(await LicenseRepository.getUserAcceptedTos(this.email)
          .then((d) => d.data)
          .catch(() => true))
      ) {
        this.localDialog = true;
        return;
      }

      this.localDialog = false;
      this.$emit("accepted");
    },
  },
};
</script>
